<template>
	<el-form :model="deviceForm" ref="deviceForm" label-width="100px">
		<div v-for="(item, index) in deviceDataForm" :key="index">
			<el-form-item :label="item.label" v-if="item.isInput != false" :rules="item.rules" :prop="item.attr">
				<el-input v-if="item.type == 'textarea'" type="textarea" v-model="deviceForm[item.attr]" :placeholder="item.placeholder"></el-input>
				<el-input-number v-else-if="item.type == 'number'" v-model="deviceForm[item.attr]" :precision="2" :step="0.1" :max="100000" />
				<el-input v-else v-model="deviceForm[item.attr]" :placeholder="item.placeholder"></el-input>
			</el-form-item>
		</div>
		<el-form-item>
			<el-button type="primary" @click="submitForm('deviceForm')">保存</el-button>
			<el-button @click="resetForm('deviceForm')">重置</el-button>
		</el-form-item>
	</el-form>
</template>

<script>
export default {
	name: "saveBidDevice",
	props: {
		deviceId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			deviceForm: {
				Id: 0,
				DeviceName: "",
				DeviceNo: "",
				DeviceBrand: "",
				DeviceParameters: "",
				DeviceUnit: "",
				DeviceNumber: 0,
				DevicePrice: 0,
				DeviceInternalQuotation: 0,
				DeviceExternalQuotation: 0,
				DeviceProcurementChannels: "",
				RemarkInfo: "",
			},
			deviceDataForm: [
				{
					label: "Id",
					attr: "Id",
					isInput: false,
				},
				{
					label: "设备名称",
					attr: "DeviceName",
					placeholder: "请输入设备名称",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入设备名称",
						},
						{
							min: 1,
							max: 50,
							message: "长度不大于50个字符",
							trigger: "blur",
						},
					],
				},
				{
					label: "设备型号",
					attr: "DeviceNo",
					placeholder: "请输入设备型号",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入设备型号",
						},
						{
							min: 1,
							max: 100,
							message: "长度不大于100个字符",
							trigger: "blur",
						},
					],
				},
				{
					label: "设备品牌",
					attr: "DeviceBrand",
					placeholder: "请输入设备品牌",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入设备品牌",
						},
						{
							min: 1,
							max: 50,
							message: "长度不大于50个字符",
							trigger: "blur",
						},
					],
				},
				{
					label: "设备参数",
					attr: "DeviceParameters",
					placeholder: "请输入设备参数",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入设备参数",
						},
						{
							min: 1,
							max: 500,
							message: "长度不大于500个字符",
							trigger: "blur",
						},
					],
					type: "textarea",
				},
				{
					label: "单位",
					attr: "DeviceUnit",
					placeholder: "请输入单位",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入单位",
						},
						{
							min: 1,
							max: 10,
							message: "长度不大于10个字符",
							trigger: "blur",
						},
					],
				},
				{
					label: "数量",
					attr: "DeviceNumber",
					placeholder: "请输入数量",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入数量",
						},
					],
					type: "number",
				},
				{
					label: "设备进价",
					attr: "DevicePrice",
					placeholder: "请输入设备进价",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入设备进价",
						},
					],
					type: "number",
				},
				{
					label: "内部报价",
					attr: "DeviceInternalQuotation",
					placeholder: "请输入内部报价",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入内部报价",
						},
					],
					type: "number",
				},
				{
					label: "外部报价",
					attr: "DeviceExternalQuotation",
					placeholder: "请输入外部报价",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入外部报价",
						},
					],
					type: "number",
				},
				{
					label: "采购渠道",
					attr: "DeviceProcurementChannels",
					placeholder: "请输入采购渠道",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入采购渠道",
						},
						{
							min: 1,
							max: 100,
							message: "长度不大于100个字符",
							trigger: "blur",
						},
					],
				},
				{
					label: "备注",
					attr: "RemarkInfo",
					placeholder: "请输入备注（长度不大于100个字符）",
					rules: [
						{
							min: 0,
							max: 100,
							message: "长度不大于100个字符",
							trigger: "blur",
						},
					],
					type: "textarea",
				},
			],
		};
	},
	mounted() {
		var that = this;
		that.$nextTick(function() {
			that.initData();
		});
	},
	methods: {
		initData() {
			var that = this;
			if (that.$props.deviceId != 0) {
				that.$API.BidDevices.QueryById.get(that.$props.deviceId).then((res) => {
					that.deviceForm = res;
				});
			}
		},
		submitForm(formName) {
			var that = this;
			that.$refs[formName].validate((valid) => {
				if (valid) {
					that.$API.BidDevices.AddOrUpdate.post(that.deviceForm).then((res) => {
						if (res.Success) {
							that.$message.success(res.Message);
							that.$emit("operationSuccess");
						} else {
							that.$message.error(res.Message);
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
	},
};
</script>

<style></style>
